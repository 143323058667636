.ourMissionBackground{
    height: 500px;
    opacity: 0.85;
    /* background-color: #eeeee4; */
}

.ourMissionTitle{
font-size: 36px !important;
font-weight: 700 !important;
margin: 0px 0 20px 0 !important;
padding-top: 120px;
color: black !important;
}

.ourMissionBody{
    font-size: 14px !important;
    font-weight: 500 !important;
    color: black !important;
    width: 50%;
}

.aboutUsButton{
background-color: blue !important;
color: #ffffff !important;
padding: 15px !important;
text-transform: none !important;
font-size: 14px !important;
}