.footerContent{
    text-align: center;
    font-size: 12px !important;
    font-weight: 500 !important;
}
.backGround{
    background-color: white;
    max-width: 100%;
    padding: 30px 0px;
    position: absolute;
    left: 0;
    right: 0;
    /* bottom: 0; */
    /* margin-top: 100vh; */
}
.divider{
    /* width: 80%; */
    height: 1px;
    background-color: black;
    margin: 20px auto 20px auto;
}

.footerTitle{
    font-size: 18px !important;
    font-weight: 700 !important;
    color: blue !important;
}