/* .bannerBackground{
    background-image: url("../assets/discoverBannerBackground.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 500px;
    opacity: 0.85;
} */

.bannerTitlePartnership{
    font-size: 36px !important;
    font-weight: 700 !important;
    margin: 0px 0 20px 0 !important;
    padding-top: 120px;
    text-align: center;
}

.bannerBody{
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
}

.bannerButton{
background-color: #ffffff;
color: black;
padding: 15px;
text-transform: none !important;
font-size: 14px;
}

.carousel-container{
    /* background: transparent; */
    /* overflow: auto; */
}