.manufactureBackground{
    /* background-image: url("../assets/discoverBannerBackground.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    height: 600px;
    opacity: 0.85;
}

.bannerTitleManufacture{
font-size: 36px !important;
font-weight: 700 !important;
margin: 0px 0 20px 0 !important;
padding-top: 120px;
text-align: center;
}

.bannerBodyManufacture{
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #ffffff !important;
    width: 50%;
}

.bannerButton{
background-color: #ffffff !important;
color: black !important;
padding: 15px !important;
text-transform: none !important;
font-size: 14px !important;
}

.contentBoxManufacture{
    background-color: blue;
    width: 400px;
    height: 280px;
    margin-right: -45px;
    padding: 15px;
}

.contentBoxTextManufacture{
    color: white;
    font-size: 14px !important;
}