.innovationBackground{
    height: 600px;
    opacity: 0.85;
    background-color: lightcyan;
}

.bannerTitleInnovation{
font-size: 36px !important;
font-weight: 700 !important;
/* text-align: center; */
}

.bannerBodyInnovation{
    font-size: 14px !important;
    font-weight: 500 !important;
    /* width: 50%; */
}

.bannerButton{
background-color: #ffffff !important;
color: black !important;
padding: 15px !important;
text-transform: none !important;
font-size: 14px !important;
}

.contentBoxInnovation{
    background-color: blue;
    width: 220px;
    height: 180px;
    margin-right: -210px;
    margin-top: -200px;
}