.ourTeamBackground{
    /* background-image: url("../assets/discoverBannerBackground.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    height: 650px;
    opacity: 0.85;
}

.ourTeamTitle{
    font-size: 36px !important;
    font-weight: 700 !important;
    margin: 0px 0 20px 0 !important;
    padding-top: 120px;
    color: black;
    text-align: center;
}

.ourTeamBody{
    font-size: 12px;
    font-weight: 500;
    color: black;
}

.ourTeamButton{
background-color: blue;
color: #ffffff;
padding: 15px;
text-transform: none !important;
font-size: 14px;
}

.cardOurTeam{
    width: 340px !important;
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 10px;
    min-height: 350px !important;
}
.cardOurTeamDescription{
    padding: 10px 10px 20px 10px !important;
    font-size: 13px !important;
    text-justify: inter-word !important;
}

.cardTeamDesignation{
    font-size: 12px !important;
}