.bannerBackgroundCommitment{
    /* background-image: url("../assets/commitment.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    height: 600px;
    opacity: 0.85;
}

.bannerTitleCommitment{
font-size: 36px !important;
font-weight: 700 !important;
margin: 0px 0 20px 0 !important;
padding-top: 120px;
/* color: #ffffff !important; */
}

.bannerBodyCommitment{
    font-size: 14px !important;
    font-weight: 500 !important;
    /* color: #ffffff !important; */
    /* width: 50%; */
}

.bannerButtonCommitment{
background-color: #ffffff !important;
color: black !important;
padding: 15px !important;
text-transform: none !important;
font-size: 14px !important;
}